<template>
	<div class="fullscreen">

		<div class="topbar">
			<div>
				<img class="mt-4 mb-4" src="/images/fiber.png" alt="Fiber NL" />
			</div>
		</div>

		<div class="center-box mt-5">
			<div>
				<h2>Deze order is reeds verwerkt</h2>

				<p>Er is al een order met precieze dezelfde gegevens ontvangen in de afgelopen 5 minuten.</p>

				<div class="mt-5" style="display: flex; justify-content: center;">
					<b-button @click="$emit('reset')" class="btn-light" style="padding: 0.5em 2em;">Volgende klant</b-button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	props: [],
	emits: [],
	data() {
		return {
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;
$fiberGreen: #91c750;
$fiberOrange: #ef7a30;

.topbar {
	background-color: #fff !important;
	display: flex;
	justify-content: center;
}

.fullscreen {
	height: 100vh;
	background-color: #eee;
}

h2 {
	color: $fiberBlue;
	font-size: 2em;
	margin-bottom: 0.75em;
}

p {
	font-size: 1.5em;
}

h3 {
	font-size: 1.5em;
}

.center-box {
	display: flex;
	justify-content: center;
}

.center-box div {
	background-color: #fff;
	padding: 3em;
	border-radius: 1em;
}

</style>
