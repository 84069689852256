<template>
	<div>
		<b-navbar toggleable="lg" type="dark">
			<b-navbar-brand href="#" class="brand-logo">
				<img src="/images/fiber.png" alt="Fiber NL" @click="$emit('reset-form')" />
			</b-navbar-brand>

			<b-navbar-nav class="ml-auto navbar">
				<b-button-group size="md" class="nav-buttons">
					<b-button v-for="step in steps" :key="step.id" :disabled="isActiveStep(step.id)" @click="$emit('select-step', step.id)" :class="getBtnClass(step.id)">
						{{ step.label }}
					</b-button>
				</b-button-group>
			</b-navbar-nav>

		</b-navbar>
	</div>
</template>

<script>
export default {
	name: 'Navbar',
	props: ['activeStep', 'completedSteps'],
	emits: ['select-step'],
	data()
	{
		return {
			'steps': [
				{'id': 1, 'label': '1: Pakket'},
				{'id': 2, 'label': '2: Opties'},
				{'id': 3, 'label': '3: Gegevens'},
				{'id': 4, 'label': '4: Overzicht'},
				{'id': 5, 'label': '5: Akkoord'},
			]
		}
	},
	computed: {
	},
	methods: {

		isActiveStep(stepId)
		{
			let ret = false;

			// Plan is always enabled
			if (stepId === 1)
			{
				return false;
			}

			// Options: Enabled after plan is chosen (disabled => false)
			if (stepId === 2)
			{
				return !this.completedSteps.plan;
			}

			// Data: Enabled after plan + options are done
			if (stepId === 3)
			{
				return !(this.completedSteps.plan && this.completedSteps.options);
			}

			// Overview: Enabled after plan + options + data are done
			if (stepId === 4)
			{
				return !(this.completedSteps.plan && this.completedSteps.options && this.completedSteps.customerData);
			}

			// Agreement: Enabled after plan + options + data + overview are done
			if (stepId === 5)
			{
				return !(this.completedSteps.plan && this.completedSteps.options && this.completedSteps.customerData && this.completedSteps.overview);
			}

			return ret;
		},

		getBtnClass(stepId)
		{
			return (stepId === this.activeStep ? 'active-step' : '');
		}

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;
$fiberGreen: #91c750;

.brand-logo img {
	height: 40px;
}

nav.navbar {
	background-color: #eee !important;
	box-shadow: 0 4px 8px 0px rgba(0,0,0, 0.2);
}

.nav-buttons button {
	background-color: $fiberBlue;
	border: none !important;
}

.nav-buttons button.active-step {
	background-color: $fiberGreen;
}

.version {
	color: #999;
}

</style>
