//import '@babel/polyfill'
//import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
//import router from './router'
//import store from './store'
import moment from 'moment'
import axios from 'axios'
import VueAxios from 'vue-axios'

var numeral = require("numeral");
const ibantools = require('ibantools');

// load a locale
numeral.register('locale', 'nl', {
	delimiters: {
		thousands: '.',
		decimal: ','
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinal : function (number) {
		return number === 1 ? 'e' : 'e';
	},
	currency: {
		symbol: '€'
	}
});

// switch between locales
numeral.locale('nl');

Vue.config.productionTip = false;

//Vue.prototype.moment = moment;

Vue.filter("formatNumber", function (value)
{
	return numeral(value).format("0.00");
});

Vue.filter("formatDate", function (value)
{
	moment.locale('nl');
	return moment(value).format('dddd D MMMM YYYY');
});

Vue.prototype.$http = axios;

Vue.prototype.$iban = ibantools;

const vueapp = new Vue({
	// router,
	// store,
	render: h => h(App)
}).$mount('#app')
