<template>
	<div v-show="isVisible" class="main">

		<div class="form-section top">
			<div class="form-section-label">
				Pakket
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col">
					<div class="line-text">
						{{ order.product.plan.name }}

						<span v-if="productMode === 'helmond-try-buy' || productMode === 'efiber-try-buy'" class="highlight">(Eerste 3 maanden gratis)</span>
					</div>
					<div class="line-price">
						€ {{ order.product.plan.price | formatNumber }}
					</div>
				</div>
			</div>
		</div>
		<div class="form-section" v-show="order.product.hasTv">
			<div class="form-section-label">
				Televisie
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col" v-show="order.product.tv.canHaveExtraPackages">
					<div class="line-text mb-2">
						Extra TV pakketten
					</div>
					<div class="line-price mb-2">
						p/mnd
					</div>
					<div class="line-text">
						<label for="ziggo">
							<input type="checkbox" name="ziggo" id="ziggo" v-model="order.product.tv.extraPackages.ziggo" @change="$emit('price-affected')" /> Ziggo Sport Totaal
						</label>
					</div>
					<div class="line-price">
						€ 13,95
					</div>
					<div class="line-text" v-show="order.product.plan.code === 'basis'">
						<label for="ent_hd">
							<input type="checkbox" name="ent_hd" id="ent_hd" v-model="order.product.tv.extraPackages.entmt" @change="$emit('price-affected')" /> Entertainment
						</label>
					</div>
					<div class="line-price" v-show="order.product.plan.code === 'basis'">
						€ 5,00
					</div>
				</div>
				<div class="divider" v-show="order.product.tv.canHaveExtraPackages"></div>
				<div class="form-line-2-col mb-1" v-if="order.product.tv.provider === 'canal_digitaal'">
					<div class="line-text">
						1e settop box is gratis inbegrepen
					</div>
					<div class="line-price">
						€ 0,00
					</div>
				</div>
				<div class="form-line-2-col mb-1" v-if="order.product.tv.provider === 'nlziet'">
					<div class="line-text">
						NLZIET app
					</div>
					<div class="line-price">
						Inbegrepen
					</div>
				</div>
				<div class="form-line-2-col">
					<div style="padding-top: 0.5em;">
						Extra settop box, € {{ stbPrice | formatNumber }} p/st p/mnd <br />
						<span v-if="order.product.tv.provider === 'nlziet' && order.product.tv.extraSettopBoxes > 0">+ € 25 eenmalige kosten</span>
					</div>
					<div>
						<b-form-select name="extra_stb" :options="stbOptions" v-model="order.product.tv.extraSettopBoxes" @change="$emit('price-affected')">
						</b-form-select>
					</div>
				</div>
			</div>
		</div>

		<div class="form-section">
			<div class="form-section-label">
				Telefonie
				<div class="show-phone-toggle">
					<b-button ref="toggleBtn" size="sm" class="light-btn"  @click="togglePhoneOptions(); $emit('price-affected');">{{ addPhoneButtonText }}</b-button>
				</div>
			</div>
			<div class="form-section-box">
				<div v-show="!order.product.hasPhone">
					Geen telefonie.
				</div>
				<div v-show="order.product.hasPhone">

					<div class="form-line-2-col">
						<div class="line-text">
							Vaste telefoonlijn
						</div>
						<div class="line-price">
							€ 1,00
						</div>
					</div>
					<div class="form-line-2-col-50-50 mb-1">
						<div>
							<b-form-group>
								<b-form-radio v-model="order.product.phone.firstNumber.migrate" :value="false" @change="cleanFirstPhoneNumber">Nieuw nummer</b-form-radio>
								<b-form-radio v-model="order.product.phone.firstNumber.migrate" :value="true">Nummer behouden</b-form-radio>
							</b-form-group>
							<b-form-input :state="firstNumberState" v-show="order.product.phone.firstNumber.migrate" :formatter="formatPhoneNumber" v-model="order.product.phone.firstNumber.numberToMigrate" placeholder="Te behouden telnr" />
						</div>
						<div v-show="order.product.phone.firstNumber.migrate !== null">
							<p class="mb-1">
								<b-form-checkbox v-model="order.product.phone.hasSecondNumber" :value="true" @change="cleanSecondPhoneNumber(), $emit('price-affected')">2e nummer (€ 4,95 p/mnd)</b-form-checkbox>
							</p>
							<b-form-group v-show="order.product.phone.hasSecondNumber">
								<b-form-radio v-model="order.product.phone.secondNumber.migrate" :value="false" @change="cleanSecondPhoneNumber(), $emit('price-affected')">Nieuw nummer</b-form-radio>
								<b-form-radio v-model="order.product.phone.secondNumber.migrate" :value="true" @change="$emit('price-affected')">Nummer behouden (€ 9,95 eenmalig)</b-form-radio>
							</b-form-group>
							<b-form-input
								:state="secondNumberState"
								v-show="order.product.phone.hasSecondNumber && order.product.phone.secondNumber.migrate"
								v-model="order.product.phone.secondNumber.numberToMigrate"
								:formatter="formatPhoneNumber"
								placeholder="Te behouden telnr"
							/>
							<div class="form-error" v-show="duplicatePhoneNumber">Het eerste en tweede telefoonnummer zijn hetzelfde.</div>
						</div>
					</div>

					<div class="divider" v-show="order.product.phone.firstNumber.migrate !== null"></div>
					<div class="form-line-2-col" v-show="order.product.phone.firstNumber.migrate !== null">
						<div class="line-text">
							Belbundel
						</div>
						<div class="line-price">
							p/mnd
						</div>
						<div class="line-text">
							<b-form-radio v-model="order.product.phone.package.code" value="" @change="$emit('price-affected'), setPhonePackageName()">Goedkoop bellen vanaf 11ct/min</b-form-radio>
						</div>
						<div class="line-price">
						</div>
						<div class="line-text">
							<b-form-radio v-model="order.product.phone.package.code" value="onbeperkt_vast_nl" @change="$emit('price-affected'), setPhonePackageName()">Onbeperkt vast NL</b-form-radio>
						</div>
						<div class="line-price">
							€ 9,95
						</div>
						<div class="line-text">
							<b-form-radio v-model="order.product.phone.package.code" value="onbeperkt_vast_mobiel_nl" @change="$emit('price-affected'), setPhonePackageName()">Onbeperkt vast en mobiel NL</b-form-radio>
						</div>
						<div class="line-price">
							€ 14,95
						</div>
						<div class="line-text">
							<b-form-radio v-model="order.product.phone.package.code" value="onbeperkt_nl_buitenland_vast" @change="$emit('price-affected'), setPhonePackageName()">Onbeperkt vast en mobiel NL + buitenland vast</b-form-radio>
						</div>
						<div class="line-price">
							€ 24,95
						</div>
					</div>

				</div>
			</div>
		</div>

		<div class="form-section">
			<div class="form-section-label">
				Contract
			</div>
			<div class="form-section-box">
				<b-form-group>
					<b-form-radio v-model="order.product.contractTerm" value="1" @change="$emit('price-affected')">1 jaar</b-form-radio>
					<b-form-radio v-model="order.product.contractTerm" value="2" @change="$emit('price-affected')">
						2 jaar
						<span v-if="productMode === ''">(bespaar €24,95 activatiekosten)</span>
					</b-form-radio>
				</b-form-group>
			</div>
		</div>

		<div class="form-section">
			<div class="form-section-label">
				Borg
			</div>
			<div class="form-section-box">

				<div class="form-line-2-col">
					<div class="line-text">
						U ontvangt een wifi router gratis in bruikleen.
					</div>
					<div class="line-price">
						<span v-if="productMode === 'cd-promo'">
						(Geen borg)
						</span>
						<span v-if="productMode !== 'cd-promo'">
						€ 49,95
						</span>
					</div>
				</div>

			</div>
		</div>

		<div class="bottom-bar">
			<div></div>
			<div class="bottom-left">
				<div class="bottom-label">Maandelijks
					<span v-if="productMode === 'helmond-try-buy' || productMode === 'efiber-try-buy'" class="highlight">(na 3 maanden)</span>
				</div>
				<div class="bottom-label">Eenmalig</div>
				<div class="bottom-amount amount-recurring">€ {{  order.product.totalRecurring | formatNumber }}</div>
				<div class="bottom-amount amount-onetime">€ {{  order.product.totalOneOff | formatNumber }}</div>
			</div>
			<div class="bottom-right">
				<b-button :disabled="formState === false" @click="$emit('options-done')" class="btn-green">Verder</b-button>
			</div>
		</div>

	</div>
</template>

<script>

export default {
	name: 'SelectOptions',
	props: ['isVisible', 'order', 'productMode'],
	components: {},
	computed: {
		stbPrice() {
			return (this.order.product.tv.provider === 'canal_digitaal' ? 5 : 3.5);
		},
		stbOptions()
		{
			let stbCount = 4;
			if (this.order.product.tv.provider === 'nlziet')
			{
				stbCount = 3;
			}
			let list = [];
			list.push({text: '-', value: 0, selected: true});
			for (let i = 1; i <= stbCount; i++)
			{
				list.push({
					text: i.toString(),
					value: i
				});
			}
			return list;
		},
		duplicatePhoneNumber()
		{
			if (this.order.product.hasPhone && this.order.product.phone.firstNumber.migrate
				&& this.order.product.phone.hasSecondNumber && this.order.product.phone.secondNumber.migrate
				&& this.order.product.phone.firstNumber.numberToMigrate === this.order.product.phone.secondNumber.numberToMigrate)
			{
				return true;
			}
			return false;
		},
		addPhoneButtonText() {
			return (this.order.product.hasPhone ? 'Weghalen' : 'Toevoegen');
		},
		firstNumberState()
		{
			let ret = null;
			if (this.order.product.hasPhone && this.order.product.phone.firstNumber.migrate)
			{
				ret = /^0[0-9]\d{8}$/.test(this.order.product.phone.firstNumber.numberToMigrate);
			}
			return ret;
		},
		secondNumberState()
		{
			let ret = null;
			if (this.order.product.hasPhone && this.order.product.phone.secondNumber.migrate)
			{
				ret = /^0[0-9]\d{8}$/.test(this.order.product.phone.secondNumber.numberToMigrate);
			}
			return ret;
		},

		// Returns true if form is OK, otherwise false
		formState()
		{
			// Form is OK by default
			let ret = true;

			// If has phone but no choice was made for 1st number
			if (this.order.product.hasPhone && this.order.product.phone.firstNumber.migrate === null)
			{
				ret = false;
			}

			// If has phone, and wants to migrate 1st number, and first number is not ok, disable
			if (this.order.product.hasPhone && this.order.product.phone.firstNumber.migrate && this.firstNumberState === false)
			{
				ret = false;
			}

			// If has phone but no choice was made for 2nd number
			if (this.order.product.phone.hasSecondNumber && this.order.product.phone.secondNumber.migrate === null)
			{
				ret = false;
			}

			// If has 2nd phone, and wants to migrate 2nd number, and 2nd number is not ok, disable
			if (this.order.product.phone.hasSecondNumber && this.order.product.phone.secondNumber.migrate && this.secondNumberState === false)
			{
				ret = false;
			}

			// If has first + second number to migrate, they can't be the same
			if (this.duplicatePhoneNumber)
			{
				ret = false
			}

			this.$emit('options-form-state', ret);

			return ret;
		},
	},
	emits: ['price-affected', 'options-form-state'],
	data() {
		return {
			extra_stbs: [{text: '-', value: 0, selected: true}, {text: '1', value: 1}, {text: '2', value: 2}, {text: '3', value: 3}, {text: '4', value: 4}],
		}
	},
	methods: {

		togglePhoneOptions(event)
		{
			this.order.product.hasPhone = !this.order.product.hasPhone;

			if (!this.order.product.hasPhone)
			{
				this.order.product.phone.firstNumber.migrate = null;
				this.order.product.phone.firstNumber.numberToMigrate = null;
				this.order.product.phone.hasSecondNumber = null;
				this.order.product.phone.secondNumber.migrate = null;
				this.order.product.phone.secondNumber.numberToMigrate = null;
			}
		},

		cleanFirstPhoneNumber()
		{
			if (this.order.product.phone.firstNumber.migrate === false)
			{
				this.order.product.phone.firstNumber.numberToMigrate = null;
			}
		},

		cleanSecondPhoneNumber()
		{
			if (this.order.product.phone.hasSecondNumber === false)
			{
				this.order.product.phone.secondNumber.migrate = null;
				this.order.product.phone.secondNumber.numberToMigrate = null;
			}
			if (this.order.product.phone.secondNumber.migrate === false)
			{
				this.order.product.phone.secondNumber.numberToMigrate = null;
			}
		},

		formatPhoneNumber(val)
		{
			let cleanValue = val.replace(/\D/g, '');
			cleanValue = cleanValue.substring(0, 10);
			return cleanValue;
		},

		setPhonePackageName()
		{
			switch (this.order.product.phone.package.code)
			{
				case '':
					this.order.product.phone.package.name = 'Geen belbundel';
					this.order.product.phone.package.price = 0;
					break;
				case 'onbeperkt_vast_nl':
					this.order.product.phone.package.name = 'Onbeperkt vast NL';
					this.order.product.phone.package.price = 9.95;
					break;
				case 'onbeperkt_vast_mobiel_nl':
					this.order.product.phone.package.name = 'Onbeperkt vast en mobiel NL';
					this.order.product.phone.package.price = 14.95;
					break;
				case 'onbeperkt_nl_buitenland_vast':
					this.order.product.phone.package.name = 'Onbeperkt vast en mobiel NL + buitenland vast';
					this.order.product.phone.package.price = 24.95;
					break;
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;

.bottom-bar {
	margin-top: 1em;
	display: grid;
	grid-template-columns: 20% 5fr 1fr;
	grid-column-gap: 2em;
}

.bottom-left {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 3rem 3rem;
	grid-column-gap: 2em;
	align-items: center;
}

.bottom-right {
	//display: flex;
	//justify-items: right;
	padding-top: 3rem;
}

.bottom-label {
	font-weight: bold;
}

.bottom-amount {
	padding: 0.5em 1.5em;
	border-radius: 0.5em;
	font-size: 1.25em;
}

.bottom-amount.amount-recurring {
	background-color: $fiberBlue;
	color: white;
	font-weight: bold;
}

.bottom-amount.amount-onetime {
	background-color: #f6f6f6;

}

.highlight {
	background-color: #ffffc0;
}

</style>
